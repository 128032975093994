import Header from './header';
import Login from './login';
import PlannerTechnician from './planner_technician';
import Contracts from './contracts';
import ContractForm from './contract_form';
import Loading from './loading';
import UserForm from './user_form';
import { createConsumer } from "@rails/actioncable"
import Stats from './stats';
import Inventory from './pages/inventory';
import TimeStats from './pages/time_stats';
import ManufacturersDocs from './pages/manufacturers_docs';

const initialState = {
  user: null,
  defaultValue: '',
  loading: true,
  accessToken: null,
  mobileNav: false,
  page: 'planner',
  products: [],
  customers: [],
  contracts: [],
  manufacturers: [],
  manufacturers_docs: [],
  users: [],
  online: navigator.onLine,
  updateAvailable: false,
  contractsToSync: [],
  uploadsToSync: [],
};

const availablePages = [
  'planner',
  'contracts',
  'contract-form',
  'user-form',
  'contracts-to-sync',
  'stats',
  'installations',
  'inventory',
  'docs',
  'time-stats',
  'manufacturers-docs',
];

class MasterContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState;

    this.toggleMobileNav = this.toggleMobileNav.bind(this);
  }

  validToken(token) {
    return token && new Date(token.expires_at * 1000) > new Date();
  }

  componentDidMount() {
    window.master = this
    let _this = this;

    localforage
      .getItem('masterState')
      .then((value) => {
        let page = 'planner';
        if (availablePages.includes(window.location.pathname.split('/')[1])) {
          page = window.location.pathname.split('/')[1];
        }

        if (value && value.accessToken && this.validToken(value.accessToken)) {
          _this.setState(
            Object.assign(value, {
              page: page,
              online: navigator.onLine,
              updateAvailable: false,
              // uploadsToSync: []
            })
          );

          // WARNING this does not work, forget it
          // localforage.getItem('uploadsToSync')
          //   .then(values => {
          //     console.log(values)
          //     let a = []
          //     for (var key in values) {
          //       a.push(values[key])
          //     }
          //     console.log(a)
          //     _this.setState({
          //       uploadsToSync: Object.assign([], a)
          //     })
          //   })
        } else {
          _this.setState({ loading: false, page: page });
        }
      })
      .catch((err) => {
        console.log(err);
        if (window.Sentry) {
          window.Sentry.captureMessage(err);
        }
      });

    window.onpopstate = (e) => {
      let modal = document.querySelector('#modal');
      if (modal && modal.classList.contains('show')) {
        modal.querySelector('button.close')?.click();
        e.preventDefault();
      } else {
        if (e.state && e.state.page) {
          this.setState({ page: e.state.page });
        } else {
          this.setState({ page: 'planner' });
        }
      }
    };

    window.addEventListener('online', function () {
      _this.setState({ online: true });
      _this.performSync();
    });
    window.addEventListener('offline', function () {
      _this.setState({ online: false });
    });

    // Check for update right after initial load
    this.checkForUpdate();

    // Set interval for offline sync each min
    this.offlineSyncInterval = setInterval(function () {
      _this.performSync();
      if (window.registration) {
        window.registration.update();
      }
    }, 30000);
  }

  componentWillUnmount() {
    if (this.offlineSyncInterval) clearInterval(this.offlineSyncInterval);

    window.removeEventListener('online', function () {
      _this.setState({ online: true });
      _this.performSync();
    });
    window.removeEventListener('offline', function () {
      _this.setState({ online: false });
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const _this = this;

    localforage
      .setItem('masterState', JSON.parse(JSON.stringify(this.state)))
      .catch((err) => {
        console.log(err);
        if (window.Sentry) {
          window.Sentry.captureMessage(err);
        }
      });
    // localforage.setItem('uploadsToSync', Object.assign({}, this.state.uploadsToSync))

    // Default ajax
    jQuery.ajaxSetup({
      beforeSend: (xhr) => {
        if (this.state.accessToken && this.state.accessToken.access_token) {
          xhr.setRequestHeader(
            'Authorization',
            'Bearer ' + this.state.accessToken.access_token
          );
        }
      },
    });

    // Redirect to static
    if (this.validToken(this.state.accessToken)) {
      if (this.state.accessToken.rights != 'technician') {
        jQuery.ajax({
          method: 'POST',
          url: '/is/login',
          dataType: 'json',
          data: {
            access_token: this.state.accessToken.access_token,
          },
          success: (data) => {
            window.location = data.url;
          },
          error: (data) => {
            console.log(data);
          },
        });
      } else {
        if (!prevState.accessToken) {
          // Get user
          jQuery.ajax({
            method: 'GET',
            url: `/api/v1/users/${this.state.accessToken.user_id}`,
            dataType: 'json',
            success: (data) => {
              this.setState({ user: data, loading: false });
            },
          });

          // Fetch Data
          this.fetchProducts();
          this.fetchContracts();
          this.fetchManufacturers();
          this.fetchUsers();
          this.fetchCompanies();
          this.fetchManufacturersDocs();
        }
      }

      window.currentUser = this.state.user;
    }

    // Body class
    this.state.mobileNav
      ? document.body.classList.add('nav-opened')
      : document.body.classList.remove('nav-opened');

    // ActionCable
    if (
      prevState.user == null &&
      this.state.user != null &&
      this.state.user.token
    ) {
      let consumer = createConsumer();
      consumer.subscriptions.create(
        { channel: 'UsersChannel', token: this.state.user.token },
        {
          connected() {
            // Called when the subscription is ready for use on the server
            // console.log('connected')
          },

          disconnected() {
            // Called when the subscription has been terminated by the server
            // console.log('disconnected')
          },

          received(data) {
            // Called when there's incoming data on the websocket for this channel
            switch (data.action) {
              case 'contract_updated':
                // Fetch Contracts silently
                _this.fetchContracts(true);
                break;
              case 'customer_updated':
                // Fetch Contracts silently
                // _this.fetchCustomers(true) // NOTE not necessary
                break;
              case 'product_updated':
                // Fetch Contracts silently
                _this.fetchProducts(true);
                break;
              case 'manufacturer_updated':
                // Fetch Contracts silently
                _this.fetchManufacturers(true);
                _this.fetchManufacturersDocs(true);
                break;
              case 'user_updated':
                // Fetch Contracts silently
                _this.fetchUsers(true);
                break;
              case 'company_updated':
                // Fetch Companies silently
                _this.fetchCompanies(true);
                break;
              case 'comment_updated':
                // Fetch Comments silently
                if (window.comments) window.comments.fetchComments(true);
                break;
            }
          },
        }
      );
    }
  }

  fetchCompanies(silent = false) {
    jQuery.ajax({
      method: 'GET',
      url: '/api/v1/companies',
      data: {},
      beforeSend: (xhr) => {
        if (!silent) this.setState({ loading: true });
        if (this.state.accessToken && this.state.accessToken.access_token) {
          xhr.setRequestHeader(
            'Authorization',
            'Bearer ' + this.state.accessToken.access_token
          );
        }
      },
      success: (data) => {
        this.setState({ companies: data.results });
      },
      complete: () => {
        if (!silent) this.setState({ loading: false });
      },
    });
  }

  fetchUsers(silent = false) {
    jQuery.ajax({
      method: 'GET',
      url: '/api/v1/users',
      data: {},
      beforeSend: (xhr) => {
        if (!silent) this.setState({ loading: true });
        if (this.state.accessToken && this.state.accessToken.access_token) {
          xhr.setRequestHeader(
            'Authorization',
            'Bearer ' + this.state.accessToken.access_token
          );
        }
      },
      success: (data) => {
        this.setState({ users: data.results });
      },
      complete: () => {
        if (!silent) this.setState({ loading: false });
      },
    });
  }

  fetchCustomers(silent = false) {
    jQuery.ajax({
      method: 'GET',
      url: '/api/v1/customers',
      data: {
        not_cancelled: true,
      },
      beforeSend: (xhr) => {
        if (!silent) this.setState({ loading: true });
        if (this.state.accessToken && this.state.accessToken.access_token) {
          xhr.setRequestHeader(
            'Authorization',
            'Bearer ' + this.state.accessToken.access_token
          );
        }
      },
      success: (data) => {
        this.setState({ customers: data.results });
      },
      complete: () => {
        if (!silent) this.setState({ loading: false });
      },
    });
  }

  fetchProducts(silent = false) {
    jQuery.ajax({
      method: 'GET',
      url: '/api/v1/products',
      data: {},
      beforeSend: (xhr) => {
        if (!silent) this.setState({ loading: true });
        if (this.state.accessToken && this.state.accessToken.access_token) {
          xhr.setRequestHeader(
            'Authorization',
            'Bearer ' + this.state.accessToken.access_token
          );
        }
      },
      success: (data) => {
        this.setState({
          products: data.results.concat({
            name: 'Práce',
            label: 'Práce',
            id: -1,
            ean: '',
            users_inventory: { [this.state.user.id]: 100 },
          }),
        });
      },
      complete: () => {
        if (!silent) this.setState({ loading: false });
      },
    });
  }

  fetchManufacturers(silent = false) {
    jQuery.ajax({
      method: 'GET',
      url: '/api/v1/manufacturers',
      data: {},
      beforeSend: (xhr) => {
        if (!silent) this.setState({ loading: true });
        if (this.state.accessToken && this.state.accessToken.access_token) {
          xhr.setRequestHeader(
            'Authorization',
            'Bearer ' + this.state.accessToken.access_token
          );
        }
      },
      success: (data) => {
        this.setState({ manufacturers: data.results });
      },
      complete: () => {
        if (!silent) this.setState({ loading: false });
      },
    });
  }

  fetchManufacturersDocs(silent = false) {
    jQuery.ajax({
      method: 'GET',
      url: '/api/v1/manufacturers-docs',
      beforeSend: (xhr) => {
        if (!silent) this.setState({ loading: true });
        if (this.state.accessToken && this.state.accessToken.access_token) {
          xhr.setRequestHeader(
            'Authorization',
            'Bearer ' + this.state.accessToken.access_token
          );
        }
      },
      success: (data) => {
        this.setState({ manufacturers_docs: data.results });
      },
      complete: () => {
        if (!silent) this.setState({ loading: false });
      },
    });
  }

  fetchContracts(silent = false) {
    if (!this.state.accessToken || !this.state.accessToken.user_id) {
      return true;
    }

    jQuery.ajax({
      method: 'GET',
      url: '/api/v1/contracts',
      data: {
        user_ids: [this.state.accessToken.user_id],
      },
      beforeSend: (xhr) => {
        if (!silent) this.setState({ loading: true });
        if (this.state.accessToken && this.state.accessToken.access_token) {
          xhr.setRequestHeader(
            'Authorization',
            'Bearer ' + this.state.accessToken.access_token
          );
        }
      },
      success: (data) => {
        // this.setState({contracts: data.results})

        this.setState((state, props) => {
          let contracts = data.results.map((i) => {
            let contract = state.contracts.find(
              (ii) =>
                ii.id == i.id &&
                new Date(ii.updated_at) > new Date(i.updated_at)
            );
            if (contract) {
              return contract;
            } else {
              return i;
            }
          });

          return {
            contracts: contracts,
          };
        });
      },
      complete: () => {
        if (!silent) this.setState({ loading: false });
      },
    });
  }

  handleLogout(e) {
    e.preventDefault();
    if (confirm('Opravdu odhlásit?')) {
      jQuery.ajax({
        method: 'DELETE',
        url: '/api/v1/logout',
        beforeSend: (xhr) => {
          this.setState({ loading: true });
          if (this.state.accessToken && this.state.accessToken.access_token) {
            xhr.setRequestHeader(
              'Authorization',
              'Bearer ' + this.state.accessToken.access_token
            );
          }
        },
        success: (data) => {},
        complete: () => {
        },
      });

      localforage.removeItem('plannerTechnicianState');
      localforage.removeItem('contractsState');
      localforage
        .removeItem('masterState')
        // localforage.removeItem('uploadsToSync')
        .then(() => {
          this.setState(Object.assign(initialState, { loading: false }));
        });
    }
  }

  toggleMobileNav() {
    this.setState((state, props) => ({
      mobileNav: !state.mobileNav,
    }));
  }

  handleUpdateApp(e) {
    e.preventDefault();
    window.location.reload(true);
  }

  checkForUpdate() {
    let _this = this;
    jQuery.ajax({
      method: 'GET',
      url: '/api',
      success: (data) => {
        if (data.version && data.version != APP_VERSION) {
          _this.setState({ updateAvailable: true });
        }
      },
    });
  }

  performSync() {
    if (!this.state.online) return true;
    let _this = this;

    if (!this.validToken(this.state.accessToken)) {
      return true;
    }

    // Check for update
    _this.checkForUpdate();

    // Fetch all data silently
    if (_this.state.accessToken) {
      this.fetchProducts(true);
      this.fetchContracts(true);
      this.fetchManufacturers(true);
      this.fetchUsers(true);
      this.fetchCompanies(true);
      this.fetchManufacturersDocs(true);
    }

    // Contracts
    this.state.contractsToSync
      .filter((object) => object.syncStatus != 'error')
      .forEach((object) => {
        jQuery.ajax({
          method: 'POST',
          url: '/api/v1/contracts',
          contentType: 'application/json',
          dataType: 'json',
          data: JSON.stringify({
            contract: Object.assign({}, object.contract, { sync: true }),
            customer: object.customer,
            request: {
              sent_at: new Date().toISOString(),
              user_agent: navigator.userAgent,
            },
          }),
          beforeSend: (xhr) => {
            if (
              _this.state.accessToken &&
              _this.state.accessToken.access_token
            ) {
              xhr.setRequestHeader(
                'Authorization',
                'Bearer ' + _this.state.accessToken.access_token
              );
            }
          },
          success: (data) => {
            // Update contract on master
            _this.setState((state, props) => {
              let contracts;
              if (state.contracts.find((i) => i.uuid == data.uuid)) {
                contracts = state.contracts.map((i) => {
                  if (i.uuid == data.uuid) {
                    return data;
                  } else {
                    return i;
                  }
                });
              } else {
                contracts = contracts.concat();
                contracts.push(data);
              }

              // Remove from contractsToSync
              let contractsToSync = state.contractsToSync.filter(
                (i) => i.contract.uuid != data.uuid
              );

              return {
                contracts: contracts,
                contractsToSync: contractsToSync,
              };
            });
          },
          error: (data) => {
            if (data.responseJSON && data.responseJSON.contract) {
              let customer = Object.assign({}, object.customer, {
                errors: data.responseJSON.customer.errors,
                full_messages: data.responseJSON.customer.full_messages,
              });

              customer.places = customer.places.map((i) => {
                let place = data.responseJSON.customer.places.find(
                  (ii) => ii.uuid == i.uuid
                );
                if (place) {
                  return Object.assign(i, {
                    errors: place.errors,
                    full_messages: place.full_messages,
                  });
                } else {
                  return i;
                }
              });

              let contract = Object.assign({}, object.contract, {
                errors: data.responseJSON.contract.errors,
                full_messages: data.responseJSON.contract.full_messages,
              });

              this.setState((state, props) => {
                let contractsToSync = state.contractsToSync.map((i) => {
                  if (i.uuid == data.uuid) {
                    return {
                      syncStatus: 'error',
                      contract: contract,
                      customer: customer,
                    };
                  } else {
                    return i;
                  }
                });

                return {
                  contractsToSync: contractsToSync,
                };
              });
            } else {
              // Do nothing
              if (data.responseJSON) {
                console.log(data.responseJSON);
              } else {
                console.log('Neočekávaná chyba');
              }
            }
          },
        });
      });

    // Uploads
    this.state.uploadsToSync
      .filter((object) => object.syncStatus != 'error')
      .forEach((object) => {
        // BLOB way
        // fetch(object.url)
        // .then(res => res.blob())
        // .then(blob => {
        //   blob.name = object.name
        //   blob.lastModified = object.lastModified
        //   blob.lastModifiedDate = object.lastModifiedDate
        //
        //   let data = new FormData()
        //   data.append('upload[uuid]', object.uuid)
        //   data.append('upload[base64]', object.url)
        //   // and goin on...
        // })

        localforage.getItem(object.uuid).then((value) => {
          let data = new FormData();

          // Check if file still exists
          if (value) {
            let reader = new FileReader();
            reader.addEventListener('load', function () {
              if (reader.result) {
                data.append('upload[uuid]', object.uuid);
                data.append('upload[device_uuid]', object.device_uuid);
                data.append('upload[asset]', value);
                data.append('upload[content_type]', value.type);
                data.append('upload[filename]', value.name);

                if (object.contractId)
                  data.append('upload[contract_id]', object.contractId);
                if (object.kind) data.append('upload[kind]', object.kind);

                jQuery.ajax({
                  method: 'POST',
                  url: '/api/v1/uploads',
                  // data: {
                  //   upload: {
                  //     uuid: object.uuid,
                  //     base64: object.url,
                  //     filename: object.filename,
                  //     content_type: object.content_type,
                  //     size: object.size
                  //   }
                  // },
                  data: data,
                  processData: false,
                  contentType: false,
                  beforeSend: (xhr) => {
                    if (
                      _this.state.accessToken &&
                      _this.state.accessToken.access_token
                    ) {
                      xhr.setRequestHeader(
                        'Authorization',
                        'Bearer ' + _this.state.accessToken.access_token
                      );
                    }
                  },
                  success: (data) => {
                    _this.setState((state, props) => {
                      let uploadsToSync = state.uploadsToSync.filter(
                        (i) => i.uuid != data.uuid
                      );

                      return {
                        uploadsToSync: uploadsToSync,
                      };
                    });

                    localforage.removeItem(data.uuid);
                  },
                  error: (data) => {
                    if (data.responseJSON && data.responseJSON.errors) {
                      let upload = Object.assign({}, object.upload, {
                        errors: data.responseJSON.errors,
                        full_messages: data.responseJSON.full_messages,
                      });

                      console.log(data.responseJSON);

                      _this.setState((state, props) => {
                        let uploadsToSync = state.uploadsToSync.map((i) => {
                          if (i.uuid == data.responseJSON.uuid) {
                            localforage.removeItem(i.uuid);
                            return {
                              syncStatus: 'error',
                              upload: upload,
                            };
                          } else {
                            return i;
                          }
                        });

                        return {
                          uploadsToSync: uploadsToSync,
                        };
                      });
                    } else {
                      // Do nothing
                      if (data.responseJSON) {
                        console.log(data.responseJSON);
                      } else {
                        console.log('Neočekávaná chyba');
                      }
                    }
                  },
                });
              } else {
                // File do not exists
                _this.setState((state, props) => {
                  let uploadsToSync = state.uploadsToSync.filter(
                    (i) => i.uuid != object.uuid
                  );

                  return {
                    uploadsToSync: uploadsToSync,
                  };
                });

                localforage.removeItem(object.uuid);
              }
            });
            reader.addEventListener('error', function (e) {
              // File do not exists
              _this.setState((state, props) => {
                let uploadsToSync = state.uploadsToSync.filter(
                  (i) => i.uuid != object.uuid
                );

                return {
                  uploadsToSync: uploadsToSync,
                };
              });

              localforage.removeItem(object.uuid);
            });
            reader.readAsDataURL(value);
          } else {
            // Update without a file
            jQuery.ajax({
              method: 'POST',
              url: '/api/v1/uploads',
              data: JSON.stringify({
                upload: {
                  uuid: object.uuid,
                  device_uuid: object.device_uuid,
                },
              }),
              contentType: 'application/json',
              dataType: 'json',
              beforeSend: (xhr) => {
                if (
                  _this.state.accessToken &&
                  _this.state.accessToken.access_token
                ) {
                  xhr.setRequestHeader(
                    'Authorization',
                    'Bearer ' + _this.state.accessToken.access_token
                  );
                }
              },
              success: (data) => {
                _this.setState((state, props) => {
                  let uploadsToSync = state.uploadsToSync.filter(
                    (i) => i.uuid != data.uuid
                  );

                  return {
                    uploadsToSync: uploadsToSync,
                  };
                });

                localforage.removeItem(data.uuid);
              },
              error: (data) => {
                if (data.responseJSON && data.responseJSON.errors) {
                  let upload = Object.assign({}, object.upload, {
                    errors: data.responseJSON.errors,
                    full_messages: data.responseJSON.full_messages,
                  });

                  console.log(data.responseJSON);

                  _this.setState((state, props) => {
                    let uploadsToSync = state.uploadsToSync.map((i) => {
                      if (i.uuid == data.responseJSON.uuid) {
                        localforage.removeItem(i.uuid);
                        return {
                          syncStatus: 'error',
                          upload: upload,
                        };
                      } else {
                        return i;
                      }
                    });

                    return {
                      uploadsToSync: uploadsToSync,
                    };
                  });
                } else {
                  // Do nothing
                  if (data.responseJSON) {
                    console.log(data.responseJSON);
                  } else {
                    console.log('Neočekávaná chyba');
                  }
                }
              },
            });
          }
        });
      });
  }

  handleDestroySyncContract(contract, e) {
    if (e) e.preventDefault();
    if (e) e.stopPropagation();

    if (confirm('Opravdu odebrat zakázku ze synchronizace?')) {
      this.setState((state, props) => {
        let contractsToSync = state.contractsToSync.filter(
          (i) => i.contract.uuid != contract.uuid
        );
        return {
          contractsToSync: contractsToSync,
        };
      });
    }
  }

  render() {
    let master = this;

    if (this.state.loading) {
      return <Loading />;
    } else {
      if (
        this.state.user &&
        this.validToken(this.state.accessToken) &&
        this.state.accessToken.rights == 'technician'
      ) {
        let page = '';
        switch (this.state.page) {
          case 'planner':
            page = (
              <PlannerTechnician
                master={master}
                accessToken={this.state.accessToken}
              />
            );
            break;
          case 'contracts':
            page = (
              <Contracts master={master} accessToken={this.state.accessToken} />
            );
            break;
          case 'installations':
            page = (
              <Contracts
                master={master}
                accessToken={this.state.accessToken}
                installations={true}
              />
            );
            break;
          case 'contract-form':
            page = (
              <ContractForm
                master={master}
                contract={this.state.editContract}
                accessToken={this.state.accessToken}
              />
            );
            break;
          case 'user-form':
            page = (
              <UserForm
                master={master}
                user={this.state.user}
                accessToken={this.state.accessToken}
              />
            );
            break;
          case 'contracts-to-sync':
            page = (
              <Contracts
                master={master}
                accessToken={this.state.accessToken}
                sync={true}
                handleDestroySyncContract={this.handleDestroySyncContract.bind(
                  this
                )}
              />
            );
            break;
          case 'stats':
            page = (
              <Stats
                master={master}
                accessToken={this.state.accessToken}
                sync={true}
              />
            );
            break;
          case 'inventory':
            page = (
              <Inventory master={master} accessToken={this.state.accessToken} />
            );
            break;
          case 'time-stats':
            page = (
              <TimeStats master={master} accessToken={this.state.accessToken} />
            );
            break;
          case 'manufacturers-docs':
            page = <ManufacturersDocs master={master} />;
            break;
        }
        return (
          <div className='master-container'>
            <Header master={master} />
            <div
              className={classNames('alert alert-warning', {
                'd-none': this.state.online,
              })}
            >
              <i
                className='fas fa-exclamation-circle'
                style={{ marginRight: '1rem' }}
              ></i>
              Pracujete v režimu offline.
            </div>
            <div
              className={classNames('alert alert-warning', {
                'd-none': !this.state.updateAvailable,
              })}
            >
              <div className='row align-items-center'>
                <div className='col'>
                  <i
                    className='fas fa-exclamation-circle'
                    style={{ marginRight: '1rem' }}
                  ></i>
                  Aktualizace aplikace k dispozici.
                </div>
                <div className='col-auto'>
                  <a
                    href='#'
                    onClick={this.handleUpdateApp.bind(this)}
                    className='btn btn-sm btn-primary'
                  >
                    Aktualizovat
                  </a>
                </div>
              </div>
            </div>
            {page}
            <div className='nav-bg' onClick={this.toggleMobileNav}></div>
          </div>
        );
      } else if (this.validToken(this.state.accessToken)) {
        return <Loading />;
      } else {
        return (
          <div className='master-container'>
            <Login master={master} />
          </div>
        );
      }
    }
  }
}

export default MasterContainer;
