import { renderComponent } from '../helpers/shared_functions';

import React from 'react';
import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';

import MasterContainer from '../components/master_container';
import Whisper from '../components/whisper';
import * as Sentry from '@sentry/browser';

import 'popper.js';
import 'bootstrap';

import { I18n } from 'i18n-js';
import translations from '../locales.json';
window.I18n = new I18n(translations);
window.I18n.defaultLocale = 'cs';
window.I18n.locale = 'cs';

import '../helpers/script_google_maps.js';

// CSS
// import '../css/application.sass'

// Version
window.version = 230;

try {
  window.environment = RAILS_ENV;
} catch (err) {
  console.log('Could not set environment');
  console.log(err.message);
}

// Sentry
if (!(environment && environment == 'development')) {
  Sentry.init({
    dsn: 'https://400beffd0c2744eababc896db0130305@sentry.io/2814507',
    environment: environment,
  });
  window.Sentry = Sentry;
}

// jQuery
import jQuery from 'jquery';
window.jQuery = jQuery;
window.$ = jQuery;
window.jquery = jQuery;

// Setup moment locale
import moment from 'moment/min/moment-with-locales';
moment.locale('cs');
window.moment = moment;

// Locales
I18n.locale = 'cs';

// HelperData
import helperData from '../helper_data.json';
window.helperData = helperData;

window.mountedComponents = [];
window.whispers = () => {
  const whispers = document.querySelectorAll('input[data-whisper]');
  whispers.forEach((element) => {
    if (!element.dataset.whisperMounted) {
      element.setAttribute('autocomplete', 'off');
      element.dataset.whisperMounted = true;
      element.parentNode.classList.add('whisper-wrapper');
      let div = document.createElement('div');
      div.classList.add('whisper-container');
      element.parentNode.appendChild(div);
      let props = { input: element };
      if (element.dataset.props) {
        Object.assign(JSON.parse(element.dataset.props), {
          input: element,
        });
      }
      renderComponent({
        element: div,
        component: Whisper,
        props,
      });
    }
  });
};

const domReady = function () {
  // MasterContainer
  const root = createRoot(document.getElementById('master-container'));
  root.render(React.createElement(MasterContainer));

  window.whispers();
};

document.addEventListener('DOMContentLoaded', domReady);

window.addEventListener('beforeunload', () => {
  try {
    ReactDOM.unmountComponentAtNode(
      document.getElementById('master-container')
    );
  } catch (error) {
    // console.log(error)
  }
});

// Disable scroll on input type number
jQuery(document).on('wheel', 'input[type=number]', function (e) {
  jQuery(this).blur();
});

// Service Worker
// if ('serviceWorker' in navigator) {
//   navigator.serviceWorker
//     .register('/aga-sw.js', { scope: '/' })
//     .then((registration) => {
//       console.log('Service Worker registered with scope:', registration.scope);
//     })
//     .catch((error) => {
//       console.error('Service Worker registration failed:', error);
//     });
// }

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/aga-sw.js', { scope: '/' }).then(registration => {
      window.registration = registration

      registration.addEventListener('updatefound', () => {

        // An updated service worker has appeared in reg.installing!
        let newWorker = registration.installing;

        newWorker.addEventListener('statechange', () => {

          // Has service worker state changed?
          switch (newWorker.state) {
            case 'installed':

	            // There is a new service worker available, show the notification
              if (navigator.serviceWorker.controller && window.master) {
                window.master.setState({updateAvailable: true})
              }
              break;
          }
        })
      })

    }).catch(registrationError => {
      // console.log('SW registration failed: ', registrationError);
    });
  });
}
