import { renderComponent } from '../helpers/shared_functions';

import Loading from './loading';
import ContractModalBody from './contract_modal_body';
import Modal from './modal';
import ContractStatus from './contract_status';
import SyncStatus from './sync_status';
import Pagination from './pagination';

class Contracts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      contracts: [],
      total: 0.0,
      total_vat: 0.0,
      to_pay: 0.0,
      start_date: '',
      end_date: '',
      search: '',
      action_type: '',
      user_id: '',
      page: 1,
    };
    this.currentRequest = null;
    this.currentRequestTimeout = null;
  }

  componentDidMount() {
    if (this.props.installations) this.fetchContracts();
  }

  componentWillUnmount() {
    if (this.currentRequest) this.currentRequest.abort();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      (!prevProps.installations && this.props.installations) ||
      prevState.start_date != this.state.start_date ||
      prevState.end_date != this.state.end_date ||
      prevState.search != this.state.search ||
      prevState.action_type != this.state.action_type ||
      prevState.user_id != this.state.user_id
    ) {
      this.state.page = 1;
    }

    if (
      (!prevProps.installations && this.props.installations) ||
      prevState.start_date != this.state.start_date ||
      prevState.end_date != this.state.end_date ||
      prevState.search != this.state.search ||
      prevState.action_type != this.state.action_type ||
      prevState.user_id != this.state.user_id ||
      prevState.page != this.state.page
    ) {
      if (this.currentRequestTimeout) clearTimeout(this.currentRequestTimeout);
      this.currentRequestTimeout = setTimeout(() => {
        this.fetchContracts();
      }, 200);
    }
  }

  handlePageChange(page) {
    this.setState({ page: page });
  }

  fetchContracts(silent = false) {
    if (
      !this.props.master.state.accessToken ||
      !this.props.master.state.accessToken.user_id
    ) {
      return true;
    }

    const currentRequest = jQuery.ajax({
      method: 'GET',
      url: '/api/v1/contracts',
      data: {
        action_type: this.state.action_type,
        start_date: this.state.start_date,
        end_date: this.state.end_date,
        q: this.state.search,
        user_id: this.state.user_id,
        extended_view: this.props.installations,
        page: this.state.page,
      },
      beforeSend: (xhr) => {
        if (!silent) this.setState({ loading: true });
        if (
          this.props.master.state.accessToken &&
          this.props.master.state.accessToken.access_token
        ) {
          xhr.setRequestHeader(
            'Authorization',
            'Bearer ' + this.props.master.state.accessToken.access_token
          );
        }
        if (this.currentRequest) {
          this.currentRequest.abort();
        }
        this.currentRequest = null;
      },
      success: (data) => {
        this.setState({
          contracts: data.results,
          totalPages: data.total_pages,
        });
      },
      complete: () => {
        if (!silent) this.setState({ loading: false });
        this.currentRequest = null;
      },
    });
    this.currentRequest = currentRequest;
  }

  showContract(contract, e) {
    e.preventDefault();
    e.stopPropagation();

    let modalBody = (
      <ContractModalBody
        contract={contract}
        master={this.props.master}
        accessToken={this.props.accessToken}
      />
    );

    // LinkEvent
    let linkEvent;
    if (this.props.master) {
      if (
        this.props.master.state.user.id == contract.user_id &&
        ((moment(contract.finished_at) >= moment().add(-30, 'minutes') &&
          contract.status == 'finished') ||
          contract.status != 'finished')
      ) {
        linkEvent = (e) => {
          e.preventDefault();
          jQuery('#modal').modal('hide');
          this.props.master.setState({
            page: 'contract-form',
            editContract: contract,
          });
          history.pushState({ page: 'contract-form' }, null, 'contract-form');
        };
      } else {
        linkEvent = null;
      }
    } else {
      linkEvent = (e) => {
        e.preventDefault();
        Turbo.visit(`/is/contracts/${contract.id}/edit`);
      };
    }

    renderComponent({
      element: document.querySelector('#modal-container'),
      component: Modal,
      props: {
        title: `Detail ${
          contract.kind == 'reservation' ? 'rezervace' : 'zakázky'
        } ${contract.id}`,
        body: modalBody,
        linkTitle: 'Upravit',
        linkEvent: linkEvent,
        contract: contract,
        master: this.props.master,
      },
    });
  }

  handleEditContract(contract, e) {
    e.preventDefault();
    e.stopPropagation();
    this.props.master.setState({
      page: 'contract-form',
      editContract: contract,
    });
    history.pushState({ page: 'contract-form' }, null, 'contract-form');
  }

  handleDestroyContract(contract, e) {
    e.preventDefault();
    e.stopPropagation();
  }

  handleExternalLink(e) {
    e.stopPropagation();
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  render() {
    let contracts = this.props.master.state.contracts;
    if (this.props.sync) {
      contracts = this.props.master.state.contractsToSync.map((i) =>
        Object.assign(i.contract, { syncStatus: i.syncStatus })
      );
    }
    if (this.props.installations) {
      contracts = this.state.contracts;
    }

    let otherUsers = [];
    if (this.props.master.state.user.can_see_user_ids?.length) {
      otherUsers.push(this.props.master.state.user);
      otherUsers = otherUsers.concat(
        this.props.master.state.users.filter((i) =>
          this.props.master.state.user.can_see_user_ids.includes(i.id)
        )
      );
    }

    return (
      <React.Fragment>
        <div
          className={classNames('card bg-light', {
            'd-none': !this.props.installations,
          })}
        >
          <div className='card-body'>
            <div className='row'>
              <div className='col'>
                <input
                  placeholder='Hledat'
                  className='form-control'
                  type='text'
                  name='search'
                  value={this.state.search}
                  onChange={this.handleChange.bind(this)}
                />
              </div>
              <div className='col'>
                <select
                  name='action_type'
                  id='action_type'
                  className='form-control'
                  value={this.state.action_type}
                  onChange={this.handleChange.bind(this)}
                >
                  <option value={''}>Typ úkonu</option>
                  {this.props.master.state.user.action_types.map((i) => (
                    <option key={i} value={i}>
                      {I18n.t(`action-types.${i}`)}
                    </option>
                  ))}
                </select>
              </div>
              <div className='col d-none'>
                <input
                  className='form-control'
                  type='date'
                  name='start_date'
                  value={this.state.start_date}
                  onChange={this.handleChange.bind(this)}
                />
              </div>
              <div className='col d-none'>
                <input
                  className='form-control'
                  type='date'
                  name='end_date'
                  value={this.state.end_date}
                  onChange={this.handleChange.bind(this)}
                />
              </div>
              {otherUsers.length && (
                <div className='col'>
                  <select
                    name='user_id'
                    id='user_id'
                    className='form-control'
                    value={this.state.user_id}
                    onChange={this.handleChange.bind(this)}
                  >
                    <option value={''}>Technik</option>
                    {otherUsers.map((i) => (
                      <option key={i.id} value={i.id}>
                        {i.name}
                      </option>
                    ))}
                  </select>
                </div>
              )}
            </div>
          </div>
        </div>

        {this.props.master.state.loading || this.state.loading ? (
          <div className='mt-3'>
            <Loading />
          </div>
        ) : (
          <div className='table-wrapper'>
            <table className='table table-hover'>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Datum a čas</th>
                  <th>Doba (min)</th>
                  <th>Zákazník</th>
                  <th>
                    {I18n.t('activerecord.attributes.contract.action_type')}
                  </th>
                  <th
                    className={classNames({
                      'd-none': !this.props.installations,
                    })}
                  >
                    Technik
                  </th>
                  <th>{I18n.t('activerecord.attributes.contract.status')}</th>
                  <th
                    className={classNames('text-center', {
                      'd-none': !this.props.sync,
                    })}
                  >
                    {this.props.sync ? 'Stav synchronizace' : null}
                  </th>
                  <th></th>
                  <th
                    className={classNames('text-center', {
                      'd-none': !this.props.sync,
                    })}
                  ></th>
                  <th className='text-center d-none'></th>
                </tr>
              </thead>
              <tbody>
                {contracts.map((i) => {
                  let user =
                    this.props.master.state.users.find(
                      (ii) => ii.id == i.user_id
                    ) || {};

                  return (
                    <tr key={i.id} onClick={this.showContract.bind(this, i)}>
                      <td>{i.id}</td>
                      <td>{moment(i.start_time).format('DD.MM.YY HH:mm')}</td>
                      <td>{i.duration}</td>
                      <td>
                        {i.customer && i.place ? (
                          <React.Fragment>
                            {i.customer.name}
                            <br />
                            <small className='text-muted'>
                              {i.place.address}
                            </small>
                          </React.Fragment>
                        ) : null}
                      </td>
                      <td>{I18n.t(`action-types.${i.action_type}`)}</td>
                      <td
                        className={classNames({
                          'd-none': !this.props.installations,
                        })}
                      >
                        <span className='default-user-name no-padding'>
                          <span className='avatar'>
                            <img src={user.avatar_url} />
                          </span>
                          <span className='name'>{user.name}</span>
                        </span>
                      </td>
                      <td>
                        {i.kind == 'reservation' ? (
                          <React.Fragment>
                            <span
                              className={classNames('badge badge-secondary', {
                                'd-none': i.status == 'cancelled',
                              })}
                            >
                              Zrušeno
                            </span>
                            <br />
                            <em>Rezervace</em>
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            <ContractStatus status={i.status} />
                            {i.requests.filter((r) => r.status == 'opened')
                              .length ? (
                              <span className='badge badge-danger ml-1'>
                                {
                                  i.requests.filter((r) => r.status == 'opened')
                                    .length
                                }
                              </span>
                            ) : null}
                          </React.Fragment>
                        )}
                      </td>
                      <td
                        className={classNames('text-center', {
                          'd-none': !this.props.sync,
                        })}
                      >
                        <SyncStatus status={i.syncStatus} />
                      </td>
                      <td className='text-center'>
                        <a
                          href='#'
                          onClick={this.handleEditContract.bind(this, i)}
                          className={classNames({
                            'd-none':
                              this.props.master &&
                              i.status == 'finished' &&
                              moment(i.finished_at) <
                                moment().add(-30, 'minutes'),
                          })}
                        >
                          <i className='fas fa-pen'></i>
                        </a>
                      </td>
                      <td
                        className={classNames('text-center', {
                          'd-none': !this.props.sync,
                        })}
                      >
                        <a
                          href='#'
                          onClick={
                            this.props.handleDestroySyncContract
                              ? this.props.handleDestroySyncContract.bind(
                                  this,
                                  i
                                )
                              : null
                          }
                          className='text-danger'
                        >
                          <i className='fas fa-times'></i>
                        </a>
                      </td>
                      <td className='text-center d-none'>
                        <a
                          href='#'
                          onClick={this.handleDestroyContract.bind(this, i)}
                          className='text-danger'
                        >
                          <i className='fas fa-times'></i>
                        </a>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <div className='row'>
              <div className='col'></div>
              <div className='col-auto'>
                <Pagination
                  page={this.state.page}
                  totalPages={this.state.totalPages}
                  handlePageChange={this.handlePageChange.bind(this)}
                />
              </div>
              <div className='col'></div>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default Contracts;
