import { renderComponent } from '../helpers/shared_functions';

import ReactSelect from 'react-select';
import Modal from './modal';
import Helper from '../helpers/helper';
import Loading from './loading';
import ContractModalBody from './contract_modal_body';
import Contract from './contract';

class PlannerTechnician extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      selectedUser: null,
      start_date: moment().format('YYYY-MM-DD'),
      end_date: '',
      loading: false,
      contracts: [],
      view: 'day',
      mainMouseEvent: '',
      initialLoading: true,
    };
    this.currentRequest = null;
    this.currentUsersRequest = null;
    this.timer = null;
    this.unmounted = false;

    this.headerRef = React.createRef();
  }

  componentWillUnmount() {
    this.unmounted = true;
    if (this.currentRequest) this.currentRequest.abort();
    if (this.currentUsersRequest) this.currentUsersRequest.abort();
    if (this.timer) clearInterval(this.timer);
  }

  componentDidMount() {
    localforage.getItem('plannerTechnicianState').then((value) => {
      if (this.unmounted) return;
      if (value) {
        this.setState(Object.assign(value, { initialLoading: false }));
      } else {
        this.setState({ initialLoading: false });
      }
    });

    this.fetchUsers();

    // Current Time Offset
    this.timer = setInterval(() => this.forceUpdate(), 60000);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.start_date != this.state.start_date ||
      prevState.end_date != this.state.end_date ||
      prevState.selectedUser != this.state.selectedUser
    ) {
      this.fetchContracts();
    }

    // Save state to localStorage
    localforage.setItem(
      'plannerTechnicianState',
      JSON.parse(JSON.stringify(this.state))
    );

    // Tooltips
    // jQuery('[data-toggle="tooltip"]').tooltip()
  }

  fetchContracts() {
    if (!this.state.selectedUser) return;
    let end_date;

    switch (this.state.view) {
      case 'week':
        end_date = moment(this.state.start_date)
          .endOf('isoweek')
          .format('YYYY-MM-DD');
        break;
      case 'month':
        end_date = moment(this.state.start_date)
          .endOf('month')
          .format('YYYY-MM-DD');
        break;
      default:
        end_date = this.state.start_date;
    }

    const currentRequest = jQuery.ajax({
      method: 'GET',
      url: '/api/v1/contracts',
      data: {
        start_date: this.state.start_date,
        end_date: end_date,
        user_ids: [this.state.selectedUser.id],
      },
      beforeSend: (xhr) => {
        xhr.setRequestHeader(
          'Authorization',
          'Bearer ' + this.props.accessToken.access_token
        );

        if (this.currentRequest) {
          this.currentRequest.abort();
        }
        this.setState({ loading: true });
        this.currentRequest = null;
      },
      success: (data) => {
        this.setState({ contracts: data.results });
      },
      complete: () => {
        this.setState({ loading: false });
        this.currentRequest = null;
      },
    });
    this.currentRequest = currentRequest;
  }

  changeView(view, e) {
    let start_date, end_date;

    if (view == 'day') {
      start_date = this.state.start_date;
      end_date = this.state.start_date;
    }

    switch (view) {
      case 'day':
        start_date = this.state.start_date;
        end_date = this.state.start_date;
        break;
      case 'week':
        start_date = moment(this.state.start_date)
          .startOf('isoweek')
          .format('YYYY-MM-DD');
        end_date = moment(this.state.start_date)
          .endOf('isoweek')
          .format('YYYY-MM-DD');
        break;
      case 'month':
        start_date = moment(this.state.start_date)
          .startOf('month')
          .format('YYYY-MM-DD');
        end_date = moment(this.state.start_date)
          .endOf('month')
          .format('YYYY-MM-DD');
        break;
      default:
      // nothing
    }

    this.setState({
      view: view,
      start_date: start_date,
      end_date: end_date,
    });
  }

  fetchUsers() {
    let ids = [this.props.accessToken.user_id];
    if (this.props.master?.state?.user?.can_see_user_ids) {
      ids = ids.concat(this.props.master.state.user.can_see_user_ids);
    }
    this.currentUsersRequest = jQuery.ajax({
      method: 'GET',
      url: '/api/v1/users',
      data: {
        ids,
      },
      beforeSend: (xhr) => {
        if (this.currentUsersRequest) this.currentUsersRequest.abort();
        xhr.setRequestHeader(
          'Authorization',
          'Bearer ' + this.props.accessToken.access_token
        );
      },
      success: (data) => {
        this.setState({ users: data.results });
      },
    });
  }

  customOption(data) {
    if (!data.isDisabled) {
      return (
        <div ref={data.innerRef} {...data.innerProps}>
          <div
            className={classNames('option-custom padded', {
              focused: data.isFocused,
            })}
          >
            <div className='avatar'>
              <img src={data.data.avatar_url} />
            </div>
            <div className='text'>
              <div className='primary'>{data.data.label}</div>
            </div>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }

  handleUserChange(data) {
    this.setState({
      selectedUser: data,
    });
  }

  handleDateChange(e) {
    if (!e.target.value || !moment(e.target.value).isValid()) return true;

    let start_date;
    switch (this.state.view) {
      case 'week':
        start_date = moment(e.target.value)
          .startOf('isoweek')
          .format('YYYY-MM-DD');
        break;
      case 'month':
        start_date = moment(e.target.value)
          .startOf('month')
          .format('YYYY-MM-DD');
        break;
      default:
        start_date = e.target.value;
    }

    this.setState({
      start_date: start_date,
    });
  }

  moveOn(way, e) {
    let i = 1;
    if (way == 'prev') i *= -1;

    if (way == 'today') {
      this.setState({
        start_date: moment().startOf(this.state.view).format('YYYY-MM-DD'),
      });
    } else {
      switch (this.state.view) {
        case 'day':
          this.setState({
            start_date: moment(this.state.start_date)
              .add(i, 'day')
              .format('YYYY-MM-DD'),
          });
          break;
        case 'week':
          this.setState({
            start_date: moment(this.state.start_date)
              .add(i, 'week')
              .format('YYYY-MM-DD'),
          });
          break;
        case 'month':
          this.setState({
            start_date: moment(this.state.start_date)
              .add(i, 'month')
              .format('YYYY-MM-DD'),
          });
          break;
        default:
        // nothing
      }
    }
  }

  mainMouseEvent(eventName, e) {
    e.preventDefault();
    this.setState({
      mainMouseEvent: eventName,
      initialX: e.currentTarget.scrollLeft,
      clickX: e.pageX,
      initialY: document.documentElement.scrollTop,
      clickY: e.clientY,
    });
  }

  mainMouseMove(e) {
    if (this.state.mainMouseEvent == 'down') {
      e.currentTarget.scrollLeft =
        this.state.initialX + (this.state.clickX - e.pageX);
      document.documentElement.scrollTop =
        this.state.initialY + (this.state.clickY - e.clientY);
    }
  }

  showContract(contract, e) {
    e.preventDefault();
    e.stopPropagation();

    let modalBody = (
      <ContractModalBody
        contract={contract}
        master={this.props.master}
        accessToken={this.props.accessToken}
      />
    );

    // LinkEvent
    let linkEvent;
    if (this.props.master) {
      if (
        this.props.master.state.user.id == contract.user_id &&
        ((moment(contract.finished_at) >= moment().add(-30, 'minutes') &&
          contract.status == 'finished') ||
          contract.status != 'finished')
      ) {
        linkEvent = (e) => {
          e.preventDefault();
          jQuery('#modal').modal('hide');
          this.props.master.setState({
            page: 'contract-form',
            editContract: contract,
          });
          history.pushState({ page: 'contract-form' }, null, 'contract-form');
        };
      } else {
        linkEvent = null;
      }
    } else {
      linkEvent = (e) => {
        e.preventDefault();
        window.location = `/is/contracts/${contract.id}/edit`;
      };
    }

    renderComponent({
      element: document.querySelector('#modal-container'),
      component: Modal,
      props: {
        title: `Detail ${
          contract.kind == 'reservation' ? 'rezervace' : 'zakázky'
        } ${contract.id}`,
        body: modalBody,
        linkTitle: 'Upravit',
        linkEvent: linkEvent,
        contract: contract,
        master: this.props.master,
      },
    });
  }

  addNewContract(e) {
    e.preventDefault();
    this.props.master.setState({ page: 'contract-form', editContract: null });
    history.pushState({ page: 'contract-form' }, null, 'contract-form');
  }

  handleMainScroll(e) {
    if (!this.headerRef.current || !e.target) return;

    this.headerRef.current.scrollLeft = e.target.scrollLeft;
  }

  toggleMap(latLng, action, e) {}

  render() {
    let _this = this;
    let days = [];
    let dayFormat;

    switch (this.state.view) {
      case 'week':
        for (var i = 0; i < 7; i++) {
          days.push(moment(this.state.start_date).add(i, 'days'));
        }
        dayFormat = 'dd D.M.';
        break;
      case 'month':
        for (var i = 0; i < moment(this.state.start_date).daysInMonth(); i++) {
          days.push(moment(this.state.start_date).add(i, 'days'));
        }
        dayFormat = 'dd D.';
        break;
      default:
        days = [moment(this.state.start_date)];
        dayFormat = 'dddd D.M.YYYY';
    }

    let timeline = [];
    for (var i = 7; i <= 23; i++) {
      timeline.push([i, '00'].join(':'));
    }

    let currentTimeOffset;
    let now = moment();
    if (now.hour() >= 7 && now.hour() < 23) {
      currentTimeOffset =
        (((now.hour() - 7) * 60 + now.minute()) / 60) * Helper.hourHeight;
    }

    if (this.state.initialLoading) {
      return <Loading />;
    } else {
      let users, contracts;
      if (this.state.selectedUser) {
        users = this.state.users.filter(
          (i) => i.id == this.state.selectedUser.id
        );
        contracts = this.state.contracts;
      } else {
        users = this.state.users.filter(
          (i) => i.id == this.props.accessToken.user_id
        );
        contracts = this.props.master.state.contracts;
      }

      return (
        <div className='planner technician'>
          <div className='row planner-filter'>
            <div className='col-sm-auto date-select'>
              <div className='input-group'>
                <div className='input-group-prepend'>
                  <button
                    className='btn btn-outline-secondary'
                    onClick={this.moveOn.bind(this, 'prev')}
                  >
                    <i className='fas fa-chevron-left'></i>
                  </button>
                </div>
                <input
                  type='date'
                  className='form-control'
                  value={this.state.start_date}
                  onChange={this.handleDateChange.bind(this)}
                  required
                />
                <div className='input-group-append'>
                  <button
                    className='btn btn-outline-secondary'
                    onClick={this.moveOn.bind(this, 'next')}
                  >
                    <i className='fas fa-chevron-right'></i>
                  </button>
                </div>
              </div>
            </div>

            <div className='col-sm-auto today-button'>
              <button
                className='btn btn-outline-secondary'
                onClick={this.moveOn.bind(this, 'today')}
              >
                Dnes
              </button>
            </div>

            <div className='col-sm-auto view-select'>
              <div className='btn-group'>
                <button
                  className={classNames('btn', {
                    'btn-primary': this.state.view == 'day',
                    'btn-secondary': this.state.view != 'day',
                  })}
                  onClick={this.changeView.bind(this, 'day')}
                >
                  Denní
                </button>

                <button
                  className={classNames('btn', {
                    'btn-primary': this.state.view == 'week',
                    'btn-secondary': this.state.view != 'week',
                  })}
                  onClick={this.changeView.bind(this, 'week')}
                >
                  Týdenní
                </button>

                <button
                  className={classNames('btn', {
                    'btn-primary': this.state.view == 'month',
                    'btn-secondary': this.state.view != 'month',
                  })}
                  onClick={this.changeView.bind(this, 'month')}
                >
                  Měsíční
                </button>
              </div>
            </div>

            {this.props.master.state.user?.can_see_user_ids?.length > 0 && (
              <div className='col users-select'>
                <ReactSelect
                  value={this.state.selectedUsers}
                  onChange={this.handleUserChange.bind(this)}
                  options={this.state.users.filter(
                    (u) => u.id != this.props.accessToken.user_id
                  )}
                  defaultOptions={true}
                  placeholder='Zobrazit jako'
                  noOptionsMessage={function () {
                    return 'Nic nenalezeno';
                  }}
                  // components={{ Option: _this.customOption.bind(this), SingleValue: _this.customOption.bind(this) }}
                  isClearable={true}
                />
              </div>
            )}

            <div className='col text-right add-contract'>
              <a
                href='#'
                className='btn btn-primary nowrap'
                onClick={this.addNewContract.bind(this)}
              >
                <i className='fa fa-plus-circle'></i> Přidat zakázku
              </a>
            </div>
          </div>

          <div className='timetable'>
            <div className='header' ref={this.headerRef}>
              <div className='days'>
                {days.map((day) => (
                  <div
                    className={classNames('day', `view-${this.state.view}`, {
                      holiday: Helper.dayType(day.toDate()) != 0,
                    })}
                    key={day}
                  >
                    <div
                      className='day-name'
                      data-toggle='tooltip'
                      data-placement='bottom'
                      title={Helper.holidayName(day.toDate())}
                    >
                      {day.format(dayFormat)}
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className='timeline'>
              {timeline.map((i) => (
                <div className='hour' key={i}>
                  <div className='label'>{i}</div>
                </div>
              ))}
              <div
                className={classNames('current-time-offset', {
                  'd-none': !currentTimeOffset,
                })}
                style={{ top: currentTimeOffset + 'rem' }}
              ></div>
            </div>
            <div
              className='main'
              onMouseDown={this.mainMouseEvent.bind(this, 'down')}
              onMouseUp={this.mainMouseEvent.bind(this, 'up')}
              onMouseMove={this.mainMouseMove.bind(this)}
              onScroll={this.handleMainScroll.bind(this)}
            >
              <div className='days'>
                {days.map((day) => (
                  <div
                    className={classNames('day', `view-${this.state.view}`, {
                      holiday: Helper.dayType(day.toDate()) != 0,
                    })}
                    key={day}
                  >
                    <div className='users'>
                      {users.map((u) => (
                        <div className='user' key={u.id}>
                          <div className='user-timeline technician' key={u.id}>
                            {contracts
                              .slice(0)
                              .reverse()
                              .filter(
                                (i) =>
                                  i.status != 'cancelled' &&
                                  i.user_id == u.id &&
                                  moment(i.start_time).format('YYYY-MM-DD') ==
                                    day.format('YYYY-MM-DD')
                              )
                              .map((i) => (
                                <Contract
                                  key={i.id}
                                  contract={i}
                                  showContract={this.showContract.bind(this)}
                                  toggleMap={this.toggleMap.bind(this)}
                                />
                              ))}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default PlannerTechnician;
