
import FormErrors from './form_errors'
import Signature from './signature'
import UserAddress from './user_address'

class UserForm extends React.Component {

  constructor(props) {
    super(props);

    let defaultUser = {
      type: 'user',
      errors: {},
      full_messages: []
    }

    this.state = {
      user: (this.props.user ? Object.assign({}, defaultUser, this.props.user) : defaultUser)
    };

    this.currentRequest = null
  }

  componentDidMount() {
    if (window.initGoogleMaps) window.initGoogleMaps()
    window.userForm = this
  }

  componentWillUnmount() {
    window.userForm = null
  }

  handleInputChange(e) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState((state, props) => ({
      user: Object.assign(state.user, {[name]: value})
    }));
  }

  cancelForm(e) {
    e.preventDefault()
    if (confirm('Opravdu chcete opustit formulář bez uložení?')) {
      window.history.back();
    }
  }

  handleSubmit(e) {
    e.preventDefault()
    const currentRequest = jQuery.ajax({
      method: 'POST',
      url: '/api/v1/users',
      data: {
        user: this.state.user
      },
      beforeSend: (xhr) => {
        if (this.props.accessToken) {
          xhr.setRequestHeader ("Authorization", "Bearer " + this.props.accessToken.access_token)
        } else {
          xhr.setRequestHeader ("Authorization", "Bearer " + document.body.dataset.jwt)
        }

        if (this.currentRequest) {
          this.currentRequest.abort()
        }
        this.setState({loading: true})
        this.currentRequest = null
      },
      success: (data) => {
        this.state.contract = data
        this.props.master.setState({user: data})
        window.history.back()
      },
      error: (data) => {
        if (data.responseJSON) {
          this.setState({
            user: Object.assign({}, this.state.user, {errors: data.responseJSON.errors, full_messages: data.responseJSON.full_messages})
          })
        } else {
          alert('Neočekávaná chyba')
        }
      },
      complete: () => {
        this.setState({loading: false})
        this.currentRequest = null
      }
    })
    this.currentRequest = currentRequest
  }


  handleSignatureChange(name, value) {
    this.setState((state, props) => ({
      user: Object.assign(state.user, {[name]: value})
    }));
  }

  render() {
    let _this = this

    return (
      <div className="medium-wrapper">
        <form>
          <FormErrors errors={this.state.user.full_messages} />

          <UserAddress user={this.state.user} handleInputChange={this.handleInputChange.bind(this)} />

          {/* <div className='address-wrapper mb-3'>
            <div className='row'>
              <div className='col-sm-6'>
                <Input
                  name="street"
                  type="text"
                  object={this.state.user}
                  onChange={this.handleInputChange.bind(this)}
                  autoComplete="new-password"
                  data-google-autocomplete="true"
                  whisperScenario='address'
                />

              </div>

              <div className='col-sm-2'>
                <Input
                  name="zip"
                  type="text"
                  object={this.state.user}
                  onChange={this.handleInputChange.bind(this)}
                  autoComplete="new-password"
                />
              </div>

              <div className='col-sm-4'>
                <Input
                  name="city"
                  type="text"
                  object={this.state.user}
                  onChange={this.handleInputChange.bind(this)}
                  autoComplete="new-password"
                />
              </div>
            </div>

            <div className='row'>
              <div className='col-sm-6'>
                <Input
                  name="latitude"
                  type="number"
                  object={this.state.user}
                  onChange={this.handleInputChange.bind(this)}
                  autoComplete="new-password"
                />
              </div>

              <div className='col-sm-6'>
                <Input
                  name="longitude"
                  type="number"
                  object={this.state.user}
                  onChange={this.handleInputChange.bind(this)}
                  autoComplete="new-password"
                />
              </div>
            </div>

            <MapSelector latitude={this.state.user.latitude} longitude={this.state.user.longitude} />
          </div> */}

          <div className="card bg-light mb-4">
            <div className="card-body text-center">
              <Signature
                value={this.state.user.signature_url}
                name="helper_signature"
                handleSignatureChange={this.handleSignatureChange.bind(this)} />
            </div>
          </div>

          <div className="form-actions">
            <div className="left">
              <a href="#" className="btn btn-lg btn-secondary" onClick={this.cancelForm.bind(this)}>Zrušit</a>
            </div>
            <div className="right">
              <button className="btn btn-lg btn-success" onClick={this.handleSubmit.bind(this)}>Uložit</button>
            </div>
          </div>
        </form>

        { window.environment == 'development' ? (
          <pre className="mt-4 pre-scrollable">
            {JSON.stringify(this.state, null, 2)}
          </pre>
        ) : null}

      </div>
    )
  }
}

export default UserForm
