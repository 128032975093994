import logo from '../../assets/images/agalogo.png'

class Login extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      remember_me: false,
      loading: false,
      error: ''
    };
    this.currentRequest = null
  }

  componentDidMount() {
    document.querySelector('body').classList.add('login')
  }

  componentWillUnmount() {
    if (this.currentRequest) {
      this.currentRequest.abort()
    }
    document.querySelector('body').classList.remove('login')
  }

  handleChange(e) {
    let hash = {}
    if (e.target.type == 'checkbox') {
      hash[e.target.name] = e.target.checked
    } else {
      hash[e.target.name] = e.target.value
    }
    this.setState(hash)
  }

  handleSubmit(e) {
    e.preventDefault()
    if (this.state.loading) return true

    const currentRequest = jQuery.ajax({
      method: 'POST',
      url: '/api/v1/login',
      data: {
        remember_me: this.state.remember_me
      },
      beforeSend: (xhr) => {
        xhr.setRequestHeader ("Authorization", "Basic " + btoa(this.state.email + ":" + this.state.password));

        if (this.currentRequest) {
          this.currentRequest.abort()
        }
        this.setState({loading: true, error: ''})
        this.currentRequest = null
      },
      success: (data) => {
        this.props.master.setState({accessToken: data})
      },
      error: (data) => {
        let error = 'Špatný login nebo heslo'
        if (
          data.responseJSON &&
          data.responseJSON.message == 'user locked'
        ) {
          error = 'Příliš mnoho neplatných pokusů, zkuste to za 5 min znovu.'
        }

        this.setState({
          loading: false,
          error: error
        })
        this.currentRequest = null
      }
    })
    this.currentRequest = currentRequest
  }

  render() {

    return (
      <div className="login-wrapper">
        <form className="form-signin" onSubmit={this.handleSubmit.bind(this)}>
          <img src={logo} alt="" className="logo mb-4"/>
          <div className={classNames('alert alert-danger', {'d-none': !this.state.error})}>
            {this.state.error}
          </div>
          <div className={classNames('alert alert-warning', {'d-none': this.props.master.state.online})}>
            <i className="fas fa-exclamation-circle" style={{marginRight: '1rem'}}></i>Nejste připojeni k internetu
          </div>
          <label htmlFor="inputEmail" className="sr-only">Login</label>
          <input
            value={this.state.email}
            onChange={this.handleChange.bind(this)}
            name="email"
            type="text"
            id="inputEmail"
            className="form-control"
            placeholder="Login"
            autoFocus
            autoComplete="username"
            required />

          <label htmlFor="inputPassword" className="sr-only">Heslo</label>
          <input
            value={this.state.password}
            onChange={this.handleChange.bind(this)}
            name="password"
            type="password"
            id="inputPassword"
            className="form-control"
            placeholder="Heslo"
            autoComplete="current-password"
            required />

          <div className="form-check mb-3">
            <input
              checked={this.state.remember_me}
              onChange={this.handleChange.bind(this)}
              name="remember_me"
              type="checkbox"
              className="form-check-input"
              value="remember-me"
              id="rememberMe" />
            <label htmlFor="rememberMe" className="form-check-label">Pamatovat si mě</label>
          </div>

          <button className="btn btn-lg btn-primary btn-block" type="submit" disabled={this.state.loading}>Přihlásit se</button>
        </form>
        <div className="text-muted text-center">
          <small>{APP_VERSION}</small>
        </div>
      </div>
    )
  }
}

export default Login
